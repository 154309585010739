module.exports = {
  index: {
    metadata: {
      title: "Home",
      description:
        "Future Foundry provides high quality software development services to help startups and entrepreneurs bring their ideas to market.",
    },
    heroText:
      "Continuity in development, confidence in execution, and stability throughout the process.",
    innovations: {
      heading: "Innovations and digital products are complex",
      description:
        "From infrastructure to quality code, proper guidance and expertise are critical. Partner with Future Foundry, because having a team that understands your concerns gives you the confidence to make your digital dreams a reality.",
    },
    contentGroup1: [
      {
        heading: "Leadership",
        description:
          "We integrate seamlessly with existing teams, working in parallel, and owning entire product sprints if needed. Our team is independent, versatile and does not require additional resources on your end to get the job done.",
        primaryImage: "leadership.png",
      },
      {
        heading: "Structured",
        description:
          "We've worked with some of the largest firms in the world to help realize their product visions. We've led engineering teams, guided development and our processes ensure deadlines are met.",
        primaryImage: "structured.png",
      },
      {
        heading: "Results",
        description:
          "Whether your company needs to build something from scratch, or needs help crossing the finish line, we are the solution to get you where you want in an efficient, painless manner.",
        primaryImage: "results.png",
      },
    ],
    contentGroup2: [
      {
        heading: "Efficiency",
        title: "Efficient Integration",
        description:
          "We integrate with your existing processes to increase your product development, build new features, iterate and test.",
      },
      {
        heading: "Architecture",
        title: "Tailored architecture for scale",
        description: `Based on your existing setup and goals, we will develop a plan that ensures a smooth transition to a highly scaleable architecture.`,
        // description: `Based on your existing setup and your goals, we will develop a proper architecture plan to ensure smooth transition while better suited for scalability.\n\nWe like to leave architectures better than when we found them.`,
      },
    ],
    services: [
      "Stabilization",
      "New Product Development",
      "Feature and Feedback",
      "Full Product Rollout",
      "Architecture",
      "Integrations",
      "CTO as a Service",
      "Engineering Management",
    ],
  },
  ...require("./testimonials"),
  ...require("./startups"),
  ...require("./ourWork"),
  ...require("./joinUs"),
  ...require("./contact"),
  ...require("./blog"),
  ...require("./footer"),
}
