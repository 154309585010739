module.exports = {
  contact: {
    metadata: {
      title: "Contact",
      description:
        "Please let us know how Future Foundry can help bring your ideas to life.",
    },
    name: "Ricky Panzer, Founder & CEO",
    title: "About The Founder",
    about: `Ricky is a serial tech entrepreneur and founder. He managed a number of engineering teams and led them to ship successful products. Ricky is passionate about creating products that challenge people to rethink the way the live. He created products from gaming devices to robotic furniture, and taught over 100 students 1 on 1 as a top rated mentor.
    \n\nRicky's experience spans various company sizes as a founder and a leader of large corporate tech teams.
    `,
  },
}
