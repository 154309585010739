module.exports = {
  ourWork: {
    metadata: {
      title: "Our Work",
      description:
        "Future Foundry has worked with venture capital backed startups as well as established corporations. Read more about some of our work here.",
      shortDescription: "See what we've helped other teams accomplish:",
    },
    quote: {
      text: `"Building the muscle to hire great people is a huge competitive advantage."`,
      attribution: "- Patty McCord, Netflix",
    },
  },
}
