module.exports = {
  startups: {
    metadata: {
      title: "Startups",
      description:
        "Future Foundry can help you get ideas to market quickly and efficiently by building your product or supplementing your team.",
    },
    heroText:
      "We focus on leanness, speed, efficiency, and iteration to advance your product.",
    challenge: {
      heading: "Your Biggest Challenge",
      description:
        "Your biggest challenge is often not coming up with an idea, a solution to a problem, but how to implement it. Having proper guidance is critical. Partner with Future Foundry because we know what it means to get product to market.",
    },
    contentGroup1: [
      {
        heading: "Expertise",
        description:
          "Our veteran engineers can work with your product or design team to find and architect the best, scaleable, yet lean solutions, while writing quality code.",
        primaryImage: "pyramid.png",
      },
      {
        heading: "Efficiency",
        description:
          "Speed up your product development cycle. Our development team will take your design and ideas to production, fast. Achieve all your goals with our talent.",
        primaryImage: "polyhedron.png",
      },
      {
        heading: "Iteration",
        description:
          "We move with agility. As you obtain new information from testers, let us act and build for you. Your success is our success.",
        primaryImage: "star.png",
      },
    ],
    services: [
      "MVP from Scratch",
      "Alpha to Market",
      "Feature and Feedback Iteration",
      "Full Product Rollout",
      "Full Branded Website Build",
    ],
    pride: {
      heading: "We take pride in our work",
      description:
        "We take pride in our work. That's the biggest reason you should look forward to working with us. Our team builds your product with the care that we'd build any of our own projects. We ask the tough questions to reduce the number of iterations you need, so we can bring your product to market faster.",
    },
    // whatClientsSay: {
    //   heading: "What Clients Say",
    //   quote: `"Ricky and his team always presented themselves as thought partners in the room. They truly are more than just your typical development studio. It really feels like they are just as invested as we are in our product."`,
    //   person: "Jason Smith, Lead UX Designer, BCGDV",
    // },
  },
}
