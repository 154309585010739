module.exports = {
  testimonials: [
    {
      name: "Genevieve Ryan Bellaire",
      position: "Founder & CEO of Realworld Playbook",
      image: "bellaire.jpg",
      logo: "realworld.png",
      testimonial: `"The Future Foundry team helped us get from zero to one. They've served as our consistent technical leadership in scoping product features, building and testing product iterations, and positioning ourselves for scale. We're so grateful to work with such a reliable, creative, and thoughtful team!"`,
    },
    // {
    //   name: "Henry Vogel",
    //   position: "Partner at BCG Digitial Ventures",
    //   image: "vogel.jpg",
    //   logo: "bcgdv.png",
    //   testimonial: `"Life before Future Foundry was very chaotic - setting sprint expectations was unclear and there was lots of ambiguity. With Ricky and his team we were able to streamline our development and work to a better future."`,
    // },
  ],
}
