module.exports = {
  joinUs: {
    metadata: {
      title: "Join Us",
      description:
        "Join Future Foundry’s diverse and passionate team of developers and help build tomorrow’s software.",
    },
    heroText: {
      heading: "Join our team",
      description:
        "Our team is very diverse, but one thing that we all share is our passion for learning.",
    },
    whyJoin: {
      heading: "Why Join Us?",
      description:
        "Future Foundry doesn't just provide our clients with the best resources; since our best resources are our people we invest a lot into our team. Our developers are constantly improving their skills and that drives our company's growth.",
    },
    openings: [
      {
        title: "Frontend Engineer",
        description: "New York, NY - Engineering Full-time",
      },
      {
        title: "Backend Engineer",
        description: "New York, NY - Engineering Full-time",
      },
      {
        title: "Fullstack Engineer",
        description: "New York, NY - Engineering Full-time",
      },
      {
        title: "Fullstack Engineer",
        description: "Los Angeles, CA - Engineering Part-time",
      },
    ],
  },
}
