module.exports = {
  footer: {
    legal: {
      terms: {
        title: "Terms",
        text: require("./terms"),
      },
      privacy: {
        title: "Privacy Policy",
        text: require("./privacy"),
      },
    },
  },
}
